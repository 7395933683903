@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 300;
}


* {
  font-family: "Comfortaa","RobotoMono", Courier, monospace;
  font-weight: 300;
  font-size: 14px;
  color: whitesmoke;
}

html {
  height: 100%;
  background-color: rgb(36, 36, 36);
  background-image: url(./img/spacebackground3.jpg);
  background-size: cover;
  background-repeat: repeat;
  background-blend-mode: soft-light;
  box-shadow: inset 0px 0px 30px 30px rgba(0,0,0,0.1);
  margin: 0px;
  padding: 0px;
}

body {
  margin: 0;
  padding: 0;
}

.App-header {
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: whitesmoke;
}

.App-header p {
  font-size: calc(10px + 1vmin);
}

/* MOBILE */

.click-zone-mobile {
  position: static;
  width: 100%;
  padding: 0.5rem;
  text-align: center;
  /* z-index: 2; */
  /* border: 1px solid grey; */
}
.click-zone-mobile:hover {
  cursor: pointer;
  /* z-index: 2; */
  /* border: 1px solid grey; */
}

.roomFont {
  
  color: #00ffbf90;
  font-weight: 800;
  font-size: 1.1rem;
  text-transform: uppercase;
  background-color: black;
}

.roomFont:hover {
  background-color: white;
  color: red;
}

/* DESKTOP */

.roomName {
  font-family: "Comfortaa","Roboto", "Courier New", Courier, monospace;
  font-size: 1rem;
  position: relative;
  top: 67px;
  justify-self: center;
  text-align: center;
  color: whitesmoke;
  
  /* font-size: 12px; */
  /* display: block; */
  
  /* margin-top: -20px; */
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.2);
  transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.roomName:hover {
  background-color: black;
}

.active {
  color: red;
}

/* .map-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 640px;
    height: 300px;
    background: #ffffff46;
    border: 3px groove yellow;
} */


/* .map-container:hover > * {
  opacity: 1;
} */


.container {
  position: relative;
}

.video {
  position: absolute;
  left: 0;
  top: 0;
  width: 640px;
  height: 480px;
  background: beige;
  border: 3px groove yellow;
}

.m-grid-container {
  position: fixed;
  left: 32vw;
  top: 18vh;
}

.click-zone {
  transition: 0.01s cubic-bezier(0.2, 0.8, 0.2, 1);
  /* z-index: 2; */
  /* border: 1px solid grey; */
}
.click-zone:hover {
  text-shadow: 10px 10px 10px black;
  cursor: pointer;
  
  /* transform: scale(2); */
  
  /* z-index: 2; */
  /* border: 1px solid grey; */
}

#c0:hover {
  background: url(./img/c12.png);
  background-size: cover;
}

#c1:hover {  
  background: url(./img/c10.jpg);
  background-size: cover;
}

#c2:hover {
  filter: saturate(0);
  background: url(./img/ethturin.png);
  background-size: cover;
}

#c3:hover {
  background: url(./img/noncon.png);
  background-size: cover;
}

#c4:hover {
  background: url(./img/metagame.png);
  background-size: cover;
}

#c5:hover {
  background: url(./img/c8.jpg);
  
  background-size: cover;
}

#c6:hover{
  background: url(./img/c7.jpg);
  background-size: cover;
}


#c7:hover{
  background-size: cover;
}


#c8:hover{
  
  background-size: cover;
}


#c9:hover{
  background: url(./img/c9.jpg);
  background-size: cover;
}

#c10:hover {
  
  background-repeat: no-repeat;
  background-size: cover;
}

#c11:hover {
  
  background-size: cover;
}

#c12:hover {
  background: url(./img/c6.jpg);
  background-size: cover;
}

.click-zone:hover .click-zone-highlight {
  border: solid 0.5px #00ffbf90;
  box-shadow: 0px 0px 10px #00ffbf;
  color: #00ffbf;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.247);
  
}

.click-zone:hover .roomName {
  background-color: #00000000;
  /*box-shadow: 0px 0px 10px #00ffbf;*/
  color: #00ffbf00;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.0);
}

.scale {
  transform: scale(5);
}

.modal {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: rgb(26, 26, 26);
  margin: 7% auto; /* 15% from the top and centered */
  padding: 40px;
  border: 1px solid #888;
  width: 60%; /* Could be more or less, depending on screen size */
  border-radius: 15px;
}

.modal-close {
  margin-top: 24px;
  width: 200px;
  height: 40px;
  border-radius: 8px;
  font-size: 20px;
  
}
.modal-close:hover {
  background: linear-gradient(0deg, rgb(255, 123, 0) 0%, rgb(255, 251, 0) 100%);
}
.modal_text {
  color:#00ffbf;
  font-size: 18px;
}
.modal-link {
  color: black;
}

.modal_logo {
  height: 180px;
}

